<script lang="ts">
	import { override } from '$lib/utils/classnames'

	interface Props {
		class?: string
		interactive?: boolean
		readonly?: boolean
		hoverValue?: number
		value?: number
		starColor?: string
		emptyStarColor?: string
		index?: number
		onclick?: (index: number) => void
		onmouseenter?: (index: number) => void
	}

	let {
		class: className = '',
		readonly = false,
		hoverValue = 0,
		value = 0,
		starColor = 'text-yellow-500',
		emptyStarColor = 'text-brand-gray-3',
		index = 0,
		onclick,
		onmouseenter,
	}: Props = $props()

	function getStarColor(index: number): string {
		if (!readonly && hoverValue > 0) {
			return index < hoverValue ? starColor : emptyStarColor
		}
		return index < value ? starColor : emptyStarColor
	}

	let svgClasses = $derived(override(className, getStarColor(index)))
</script>

<svg
	class={svgClasses}
	viewBox="0 0 20 20"
	aria-hidden="true"
	onclick={() => onclick?.(index)}
	onmouseenter={() => onmouseenter?.(index)}
>
	<path
		fill-rule="evenodd"
		d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
		clip-rule="evenodd"
		fill="currentColor"
	/>
</svg>
